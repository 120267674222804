/* eslint-disable no-case-declarations */
import * as types from 'constants/ActionTypes';
import { camelizeKeys } from 'humps';

/**
 * Returns an object for trip details
 * @param {*} data
 * @returns
 */
function getTripObject(data) {
  return {
    originDisplay: data.origin,
    originSlug: data.origin_slug,
    destinationDisplay: data.destination,
    destinationSlug: data.destination_slug,
    date: data.search_date,
    time: data.time,
  };
}

const initialState = {
  isLoading: false,
  ticket: {
    openTicket: '',
    origin: '',
    destination: '',
    operationDate: '',
    email: '',
    firstName: '',
    lastName: '',
    secondLastName: '',
    ticketPrice: '',
    operationNumber: '',
    nit: '',
  },
  purchaseToken: '',
  idCancellation: null,
  cancelStatus: null,
  cancelDate: '',
  hasConnections: false,
  hasConnectionsReturns: false,
  trip: {
    originDisplay: '',
    originSlug: '',
    destinationDisplay: '',
    destinationSlug: '',
    date: '',
    time: '',
  },
  trips: {
    tripDepart: {},
    tripReturn: {},
    tripDepartConnection: [],
    tripReturnConnection: [],
  },
  allPassengers: {
    depart: [],
    return: [],
  },
  selectedPassengerToAlter: {
    ticketKey: '',
    nodeTripName: '',
  },
};

export default function exchange(state = initialState, action) {
  switch (action.type) {
    case types.EXCHANGE_TYPEAHEAD_CHANGE:
      return {
        ...state,
        trip: {
          ...state.trip,
          [`${action.name}Display`]: action.display,
          [`${action.name}Slug`]: action.slug,
        },
      };
    case types.EXCHANGE_TYPEAHEAD_CLEAR:
      const origin = action.origin ? { originDisplay: '', originSlug: '' } : {};
      const destination = action.destination ? { destinationDisplay: '', destinationSlug: '' } : {};

      return {
        ...state,
        trip: {
          ...state.trip,
          ...origin,
          ...destination,
        },
      };
    case types.EXCHANGE_DATEPICKER_CHANGE:
      return {
        ...state,
        trip: {
          ...state.trip,
          date: action.date,
        },
      };
    case types.EXCHANGE_SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case types.CANCEL_TICKET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case types.STATUS_CANCEL_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case types.STATUS_CANCEL_RECEIVE:
      return {
        ...state,
        cancelStatus: action.data.status,
        cancelDate: action.data.created_at,
      };

    case types.STATUS_CANCEL_RESET:
      return {
        ...state,
        cancelStatus: null,
        cancelDate: '',
      };
    case types.CANCEL_TICKET_RECEIVE:
      return {
        ...state,
        idCancellation: action.data.id,
      };
    case types.EXCHANGE_RECEIVE:
      const { data } = action;
      const { tickets } = data;

      const trips = {
        tripDepart: getTripObject(data),
        tripReturn: {},
        tripDepartConnection: data.connections.length > 0 ? data.connections : [],
        tripReturnConnection: data.returns.connections ? data.returns.connections : [],
      };

      const hasConnections = data.connections.length > 0;
      const hasConnectionsReturns =
        !!data.returns.connections && data.returns.connections.length > 0;

      if (!!data.returns && data.returns !== null) {
        trips.tripReturn = getTripObject(data.returns);
      }

      return {
        ...state,
        purchaseToken: !!data.purchase_token && data.purchase_token,
        hasConnections,
        hasConnectionsReturns,
        trips,
        ticket: {
          ...state.ticket,
          openTicket: data.open_ticket,
          origin: data.origin,
          destination: data.destination,
          operationDate: data.operation_date,
          email: !!data.params && data.params.email,
          ticketPrice: !hasConnections && tickets[0].ticket_price,
          operationNumber: !hasConnections
            ? tickets[0].transporter_key
            : data.connections[0].tickets[0].transporter_key,
          nit: action.nit,
          document: !!data.params && data.params.document,
        },
        operationNumbers:
          !hasConnections && camelizeKeys(tickets)?.map((passenger) => passenger.transporterKey),
        passengers: !hasConnections && camelizeKeys(tickets),
        allPassengers: {
          depart: !hasConnections && camelizeKeys(tickets),
          return:
            !hasConnectionsReturns &&
            camelizeKeys(data.returns.tickets ? data.returns.tickets : []),
        },
        trip: getTripObject(data),
      };

    case types.EXCHANGE_RESET:
      return initialState;

    case types.ERROR_TRIP_EXCHANGE:
      return {
        ...state,
        errorTrip: action.errorTrip,
      };

    case types.EXCHANGE_UPDATE_PASSENGERS:
      return {
        ...state,
        passengers: action.passengers,
        trip: {
          ...state.trip,
          originDisplay: action.trip.originDisplay,
          originSlug: action.trip.originSlug,
          destinationDisplay: action.trip.destinationDisplay,
          destinationSlug: action.trip.destinationSlug,
          date: action.trip.date,
          time: action.trip.time,
        },
      };

    case types.EXCHANGE_SELECTED_PASSENGER_TO_ALTER:
      return {
        ...state,
        selectedPassengerToAlter: {
          ticketKey: action.selectedPassengerToAlter.ticketKey,
          nodeTripName: action.selectedPassengerToAlter.nodeTripName,
        },
      };

    default:
      return state;
  }
}
